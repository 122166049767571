import React, {useCallback, useEffect, useState} from 'react';
import Link from 'next/link';
import InfiniteScroll from 'react-infinite-scroller';
import {useApolloClient} from '@apollo/client';
import {Tab} from '@headlessui/react';
import {format} from 'date-fns';
import {GetMixOrdersQuery, OrderState, useGetMeQuery, useGetMixOrdersQuery} from '@graphql/generated/graphql';
import {ORDERS_STATES, PREORDERS_STATES} from '@lib/helpers/helper';
import {Spinner} from '..';

import CollapsibleTab from './CollapsibleTab';

export type SortedOrder = {
  date: string;
  mixOrders: GetMixOrdersQuery['mixOrders'];
  totalNet: number;
};

interface IMixOrdersListProps {}

const MixOrdersList: React.FC<IMixOrdersListProps> = () => {
  const [tab, setTab] = useState<'orders' | 'preorders'>('orders');
  const [sortedOrders, setSortedOrders] = useState<SortedOrder[]>([]);

  const cashierData = useGetMeQuery().data;
  const isCashier = cashierData?.me?.isCashier;

  const {cache} = useApolloClient();
  const {data, fetchMore} = useGetMixOrdersQuery({
    variables: {states: ORDERS_STATES.concat(PREORDERS_STATES), limit: 10, offset: 0},
  });

  const loadMore = useCallback(() => {
    fetchMore({variables: {offset: data.mixOrders.length}});
  }, [fetchMore, data]);

  useEffect(() => {
    return () => {
      cache.evict({id: 'ROOT_QUERY', fieldName: 'mixOrders'});
      cache.gc();
    };
  }, [cache]);

  useEffect(() => {
    setSortedOrders([]);
  }, [tab]);

  useEffect(() => {
    if (!data?.mixOrders?.length) {
      return;
    }
    data.mixOrders.forEach(mixOrder => {
      const isPreorder = PREORDERS_STATES.includes(mixOrder.state);
      const dateRaw = isPreorder ? mixOrder.scheduledAt : mixOrder.acceptedTime;
      const date = format(new Date(dateRaw), 'dd MMM yyyy');
      setSortedOrders(prev => {
        if ((tab === 'orders' && isPreorder) || (tab === 'preorders' && !isPreorder)) {
          return prev;
        }
        const hasDate = prev.find(obj => obj.date === date);
        if (hasDate) {
          return prev.map(obj => {
            const hasId = obj.mixOrders.find(({id}) => id === mixOrder.id);
            if (obj.date === date && !hasId) {
              return {
                ...obj,
                mixOrders: [...obj.mixOrders, mixOrder],
                totalNet: mixOrder.state === OrderState.Cancelled ? obj.totalNet : obj.totalNet + mixOrder.finalPrice,
              };
            }
            return obj;
          });
        }
        return [
          ...prev,
          {
            date: date,
            mixOrders: [mixOrder],
            totalNet: mixOrder.state === OrderState.Cancelled ? 0 : mixOrder.finalPrice,
          },
        ];
      });
    });
  }, [data, tab]);

  if (!data) {
    return <Spinner />;
  }

  if (!data.mixOrders.length) {
    return <NoOrders />;
  }

  return (
    <section className="pb-32 paddingHorizontal">
      <Tab.Group>
        <Tab.List className="bg-white flex rounded-md shadow">
          <Tab className="w-full focus:outline-none" onClick={() => setTab('orders')}>
            {({selected}) => <TabItem selected={selected} text="ACTIVITY" />}
          </Tab>
          <Tab className="w-full focus:outline-none" onClick={() => setTab('preorders')}>
            {({selected}) => <TabItem selected={selected} text="PREORDER" />}
          </Tab>
        </Tab.List>
        <InfiniteScroll pageStart={0} loadMore={loadMore} hasMore useWindow>
          {sortedOrders.map(({mixOrders, date, totalNet}) => (
            <CollapsibleTab key={date} mixOrders={mixOrders} date={date} totalNet={totalNet} isCashier={isCashier} />
          ))}
        </InfiniteScroll>
      </Tab.Group>
    </section>
  );
};

export default MixOrdersList;

interface ITabItemProps {
  selected: boolean;
  text: string;
}

const TabItem = ({selected, text}: ITabItemProps) => {
  return (
    <div
      className={`w-full font-medium text-sm tracking-wider py-2 hover:bg-slate-100 transition duration-300 border-b-[3px] ${
        selected ? 'border-orange-500' : 'border-white'
      }`}>
      {text}
    </div>
  );
};

const NoOrders = () => {
  return (
    <div className="fixed flex justify-center max-w-md mx-auto w-full paddingHorizontal">
      <Link href="/" passHref>
        <a className="bg-white flex flex-col justify-center items-center py-6 rounded-2xl shadow">
          <img src="/decorators/no-items.svg" alt="no-items" className="w-1/2" />
          <div className="flex flex-col text-center mt-6 gap-2">
            <p className="poppins-semibold font-lg">No orders yet</p>
            <p className="subTextGray">Click here to explore our stores</p>
          </div>
        </a>
      </Link>
    </div>
  );
};
