import React, {useEffect, useMemo, useState} from 'react';
import {addDays, addHours, format} from 'date-fns';
import {useMixOrderStore, usePersistentStore} from 'stores';
import {useRouter} from 'next/router';

interface IPreorderModalProps {
  show: boolean;
  toggle: () => void;
}

const PreorderModal = ({show, toggle}: IPreorderModalProps) => {
  const router = useRouter();
  const outletFriendlyPath = router.query.friendlyPath as string;

  const preorderDate = usePersistentStore(s => s.preorderDate);
  const setPreorderDate = usePersistentStore(s => s.setPreorderDate);

  const mixOrderInput = useMixOrderStore(s => s.getMixOrderInput(outletFriendlyPath));
  const removeCartOutlet = useMixOrderStore(s => s.removeCartOutlet);
  const hasCart = !!mixOrderInput?.stores?.length;

  const [date, setDate] = useState(preorderDate ? new Date(preorderDate) : new Date());
  const [hours, setHours] = useState<Date>(preorderDate ? new Date(preorderDate) : null);

  const handleClose = (confirm: boolean) => {
    if (!confirm) {
      setDate(preorderDate ? new Date(preorderDate) : new Date());
      setHours(preorderDate ? new Date(preorderDate) : null);
      toggle();
      return;
    }
    const updateDate = () => {
      const builtDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), hours.getHours(), hours.getMinutes());
      setPreorderDate(builtDate);
      toggle();
    };
    if (!hasCart) {
      updateDate();
      return;
    }
    if (window.confirm('Your cart will be cleared, proceed?')) {
      removeCartOutlet(outletFriendlyPath);
      updateDate();
    }
  };

  const handleDateChange = (value: Date) => {
    setDate(value);
    setHours(null);
  };

  useEffect(() => {
    if (preorderDate < addHours(new Date(), 2)) {
      setPreorderDate(null);
    }
  }, [preorderDate, setPreorderDate]);

  useEffect(() => {
    if (preorderDate) {
      return;
    }
    const timeout = setTimeout(toggle, 300);
    return () => {
      clearTimeout(timeout);
    };
  }, [toggle, preorderDate]);

  useEffect(() => {
    document.body.style.overflow = show ? 'hidden' : 'auto';
  }, [show]);

  return (
    <>
      <div
        className={`fixed max-w-x mx-auto z-90 inset-0 bg-black transform transition duration-300 ${show ? 'opacity-50' : 'opacity-0 hidden'}`}></div>
      <section
        className={`fixed overflow-scroll inset-0 max-w-md mx-auto px-2 transform transition duration-300 z-100 ${
          show ? 'translate-y-0' : 'translate-y-full'
        }`}>
        <div className={`mt-10 inset-0 flex justify-center items-center transform transition ${show ? 'translate-y-0' : 'translate-y-full'}`}>
          <div className="bg-white relative shadow rounded-xl justify-center items-center w-full py-5">
            <div className="flex flex-row justify-evenly items-start mb-3">
              <p className="poppins-semibold mb-2 w-2/3 font-lg text-center">Select ready date and time</p>
              <div className="absolute right-5 cursor-pointer" onClick={() => handleClose(false)}>
                {CROSS_SVG}
              </div>
            </div>
            <DateSelector date={date} setDate={handleDateChange} />
            <TimeSelector date={date} hours={hours} setHours={setHours} />
            <ConfirmButton onClick={() => handleClose(true)} disabled={!date || !hours} />
            <Note />
          </div>
        </div>
      </section>
    </>
  );
};

export default PreorderModal;

interface IDateSelectorProps {
  date: Date;
  setDate: (value: Date) => void;
}

const DateSelector = ({date, setDate}: IDateSelectorProps) => {
  return (
    <div className="px-10 flex mb-6 gap-1">
      {Array(5)
        .fill(null)
        .map((_, index) => {
          const day = addDays(new Date(), index);
          const selected = date.getDate() === day.getDate();
          return (
            <button
              key={index}
              type="button"
              onClick={() => setDate(day)}
              className={`w-full p-2 rounded-md transition focus:outline-none ${
                selected ? 'bg-orange-400 text-white' : 'bg-white text-black hover:opacity-50'
              }`}>
              <p className="text-sm font-medium">{index === 0 ? 'Today' : format(day, 'E')}</p>
              <p className="text-xl">{format(day, 'dd')}</p>
            </button>
          );
        })}
    </div>
  );
};

interface ITimeSelectorProps {
  date: Date;
  hours: Date;
  setHours: (value: Date) => void;
}

const TimeSelector = ({date, hours, setHours}: ITimeSelectorProps) => {
  const now = useMemo(() => new Date(), []);
  return (
    <div className="px-10 h-40 flex flex-col overflow-scroll mb-6">
      {PREORDER_TIME_DATA.map(({text, time}) => {
        const selectedHour = [hours?.getHours(), hours?.getMinutes()];
        const currentHour = [time.getHours(), time.getMinutes()];
        const selected = selectedHour[0] === currentHour[0] && selectedHour[1] === currentHour[1];

        const selectedDay = date ?? now;
        time.setFullYear(selectedDay.getFullYear(), selectedDay.getMonth(), selectedDay.getDate());
        const disabled = time < addHours(now, 2);

        return (
          <button
            key={text}
            type="button"
            onClick={() => setHours(time)}
            disabled={disabled}
            className={[
              'border-b border-slate-200 py-3 rounded-md transition focus:outline-none',
              selected ? 'bg-orange-400 text-white' : 'bg-white text-black',
              disabled ? 'opacity-25 cursor-default' : 'opacity-100 cursor-pointer',
            ].join(' ')}>
            {text}
          </button>
        );
      })}
    </div>
  );
};

interface IConfirmButtonProps {
  onClick: () => void;
  disabled?: boolean;
}

const ConfirmButton = ({onClick, disabled = false}: IConfirmButtonProps) => {
  return (
    <div className="px-10">
      <button
        type="button"
        onClick={onClick}
        className={`w-full py-1.5 mb-5 rounded-md text-white font-medium transition ${disabled ? 'bg-slate-300' : 'bg-green-500 hover:opacity-75'}`}
        disabled={disabled}>
        CONFIRM
      </button>
    </div>
  );
};

const Note = () => {
  return (
    <div className="px-10 text-sm">
      <h2 className="font-bold">Note:</h2>
      <ol className="list-disc">
        <li>Preorder is available for pickup and dine in only.</li>
        <li>You'll have to clear your cart to choose a different timeframe.</li>
      </ol>
    </div>
  );
};

const CROSS_SVG = (
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 20 20">
    <path
      fill="#b7b7b7"
      d="M10 1.6a8.4 8.4 0 1 0 0 16.8a8.4 8.4 0 0 0 0-16.8zm4.789 11.461L13.06 14.79L10 11.729l-3.061 3.06L5.21 13.06L8.272 10L5.211 6.939L6.94 5.211L10 8.271l3.061-3.061l1.729 1.729L11.728 10l3.061 3.061z"
    />
  </svg>
);

const PREORDER_TIME_DATA = [
  {
    text: '9.00 - 9.30 AM',
    time: new Date(2000, 1, 1, 9, 0),
  },
  {
    text: '9.30 - 10.00 AM',
    time: new Date(2000, 1, 1, 9, 30),
  },
  {
    text: '10.00 - 10.30 AM',
    time: new Date(2000, 1, 1, 10, 0),
  },
  {
    text: '10.30 - 11.00 AM',
    time: new Date(2000, 1, 1, 10, 30),
  },
  {
    text: '11.00 - 11.30 AM',
    time: new Date(2000, 1, 1, 11, 0),
  },
  {
    text: '11.30 - 12.00 PM',
    time: new Date(2000, 1, 1, 11, 30),
  },
  {
    text: '12.00 - 12.30 PM',
    time: new Date(2000, 1, 1, 12, 0),
  },
  {
    text: '12.30 - 1.00 PM',
    time: new Date(2000, 1, 1, 12, 30),
  },
  {
    text: '1.00 - 1.30 PM',
    time: new Date(2000, 1, 1, 13, 0),
  },
  {
    text: '1.30 - 2.00 PM',
    time: new Date(2000, 1, 1, 13, 30),
  },
  {
    text: '2.00 - 2.30 PM',
    time: new Date(2000, 1, 1, 14, 0),
  },
  {
    text: '2.30 - 3.00 PM',
    time: new Date(2000, 1, 1, 14, 30),
  },
  {
    text: '3.00 - 3.30 PM',
    time: new Date(2000, 1, 1, 15, 0),
  },
  {
    text: '3.30 - 4.00 PM',
    time: new Date(2000, 1, 1, 15, 30),
  },
  {
    text: '4.00 - 4.30 PM',
    time: new Date(2000, 1, 1, 16, 0),
  },
  {
    text: '4.30 - 5.00 PM',
    time: new Date(2000, 1, 1, 16, 30),
  },
  {
    text: '5.00 - 5.30 PM',
    time: new Date(2000, 1, 1, 17, 0),
  },
  {
    text: '5.30 - 6.00 PM',
    time: new Date(2000, 1, 1, 17, 30),
  },
  {
    text: '6.00 - 6.30 PM',
    time: new Date(2000, 1, 1, 18, 0),
  },
  {
    text: '6.30 - 7.00 PM',
    time: new Date(2000, 1, 1, 18, 30),
  },
  {
    text: '7.00 - 7.30 PM',
    time: new Date(2000, 1, 1, 19, 0),
  },
  {
    text: '7.30 - 8.00 PM',
    time: new Date(2000, 1, 1, 19, 30),
  },
  {
    text: '8.00 - 8.30 PM',
    time: new Date(2000, 1, 1, 20, 0),
  },
  {
    text: '8.30 - 9.00 PM',
    time: new Date(2000, 1, 1, 20, 30),
  },
  {
    text: '9.00 - 9.30 PM',
    time: new Date(2000, 1, 1, 21, 0),
  },
  {
    text: '9.30 - 10.00 PM',
    time: new Date(2000, 1, 1, 21, 30),
  },
  {
    text: '10.00 - 10.30 PM',
    time: new Date(2000, 1, 1, 22, 0),
  },
  {
    text: '10.30 - 11.00 PM',
    time: new Date(2000, 1, 1, 22, 30),
  },
];
